import { useCallback, useEffect, useState } from 'react';
import type { JsonLd } from 'json-ld-types';

import { CardClient as Client } from '@atlaskit/link-provider';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

type useSmartCardsEmojiResult = {
	emojiId: string | null;
};

type ResolvedDataObject = {
	data: JsonLd.Data.BaseData;
};

const sanitizeEmojiValue = (value: string | undefined) =>
	(value && value.replace(/['"]+/g, '').replace(/null/, '')) || '';

export const useSmartCardEmoji = (link: string): useSmartCardsEmojiResult => {
	const [emojiId, setEmojiId] = useState<string>();

	const getEmoji = useCallback(async () => {
		const client = new Client();
		try {
			const queriedEmojiObject = (await client.fetchData(link)) as ResolvedDataObject;
			setEmojiId(sanitizeEmojiValue(queriedEmojiObject.data['atlassian:titlePrefix']?.text));
		} catch (error) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMPANY_HUB,
			});
		}
	}, [link]);

	useEffect(() => {
		if (!!link) void getEmoji();
	}, [link, getEmoji]);

	return { emojiId: !!emojiId ? emojiId : null };
};
