import React, { useState } from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import throttle from 'lodash/throttle';

import { MediaPlacement } from '@atlaskit/smart-card';

import { CardSizes } from '../../linkCardsTypes';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';

import { HeroCardLinkComponent } from './HeroCardLinkComponent';
import { CardErrorState } from './ErrorStates';
import { useSmartCardFallbackUrl } from './useSmartCardFallbackUrl';
import type { CommonCardProps } from './commonCardProps';

export type HeroCardProps = {
	ariaLabel?: string;
	isEmptyCard?: boolean;
} & CommonCardProps;

const i18n = defineMessages({
	emptyStatePlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.hero.empty-state.placeholder-title',
		defaultMessage: 'Add a title',
		description: 'Placeholder title that is displayed in Smart Card when no title is provided',
	},
	emptyStateDescriptionPlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.hero.empty-state.description.placeholder-text',
		defaultMessage: 'Customize this dynamic frame to create a rotating carousel.',
		description:
			'Placeholder title that is displayed in Smart Card when no description is provided',
	},
});

export const BREAKPOINT_WIDTH = 440;

export const HeroCard: FC<HeroCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	confluencePageId,
	isAvatarShown,
	isPublishDateShown,
	description,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	analyticsSource,
	extensionType,
	createAnalyticsEvent,
	isEmptyCard,
	ariaLabel,
}) => {
	const intl = useIntl();
	const [heroCardWidth, setHeroCardWidth] = useState<number>(0);
	const throttledSetWidth = throttle(setHeroCardWidth, 200);
	const isVerticalLayout = heroCardWidth <= BREAKPOINT_WIDTH;

	const { emojiId } = useSmartCardEmoji(link);

	// need to use a fallback url for empty cards or the title text won't show
	const fallBackUrl = useSmartCardFallbackUrl();

	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const showDescriptionPlaceholderText = !title && !isInViewMode && !description;

	const instructionalDescriptionGuide = showDescriptionPlaceholderText
		? intl.formatMessage(i18n.emptyStateDescriptionPlaceholderTitle)
		: '';

	const handleViewAction = () => {
		createAnalyticsEvent?.({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `viewHeroCardLink`,
				source: analyticsSource,
				attributes: {
					extensionType,
					isInViewMode,
				},
			},
		}).fire();

		window.open(link, '_self');
	};

	if (isRestricted || isNoResult) {
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.HERO}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				analyticsSource={analyticsSource}
				extensionType={extensionType}
				ariaLabel={ariaLabel}
				isVerticalHeroCard={isVerticalLayout}
				handleWidthObserverSetWidth={throttledSetWidth}
			/>
		);
	}

	const previewImagePlacement = !isVerticalLayout ? MediaPlacement.Left : undefined;

	const defaultProps = {
		cardId,
		imageSrc,
		cardHeight,
		confluencePageId,
		isAvatarShown,
		isPublishDateShown,
		isVerticalLayout,
		previewImagePlacement,
		handleViewAction,
		throttledSetWidth,
		onError: trackAndCategorizeError,
		onResolve: trackSuccess,
		showDescriptionPlaceholderText,
		emojiId,
		description: description || (instructionalDescriptionGuide as any),
		ariaLabel,
		isUnauthorized,
	};

	if (isEmptyCard) {
		return (
			<HeroCardLinkComponent
				contentId={contentId}
				link={fallBackUrl}
				title={title || intl.formatMessage(i18n.emptyStatePlaceholderTitle)}
				isEmptyCard
				isDisabledTextColor={!title}
				{...defaultProps}
			/>
		);
	} else {
		return (
			<HeroCardLinkComponent contentId={contentId} link={link} title={title} {...defaultProps} />
		);
	}
};
