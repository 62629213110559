import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

import { fg } from '@confluence/feature-gating';

export type CustomSitesExtensionsState = {
	isInViewMode: boolean; // Defines if the extension is in view mode
	selectedExtensionLocalId: string | null; // In edit mode defines an active extension localId
	selectedToEditCardId: string | null; // In edit mode defines an active card id (selected on the extension and expanded on the config panel)
	selectedToRemoveCardId: string | null; // In edit mode defines an active card id that is about to be removed
};

const initialState: CustomSitesExtensionsState = {
	isInViewMode: true,
	selectedExtensionLocalId: null,
	selectedToEditCardId: null,
	selectedToRemoveCardId: null,
};

// Actions for managing the Custom Site Extensions state that is shared between the extension and the config panel
const actions = {
	setIsInViewMode:
		(isInViewMode: boolean) =>
		({ setState }: StoreActionApi<CustomSitesExtensionsState>) => {
			if (!fg('company_hub_manual_cards_refinement')) {
				return;
			}

			setState({ isInViewMode });
		},
	setSelectedExtensionLocalId:
		(extensionLocalId: string | null) =>
		({ getState, setState }: StoreActionApi<CustomSitesExtensionsState>) => {
			if (!fg('company_hub_manual_cards_refinement')) {
				return;
			}

			if (getState().isInViewMode) {
				return;
			}
			setState({ selectedExtensionLocalId: extensionLocalId });
		},
	setSelectedToEditCardId:
		(cardId: string | null) =>
		({ getState, setState }: StoreActionApi<CustomSitesExtensionsState>) => {
			if (!fg('company_hub_manual_cards_refinement')) {
				return;
			}

			if (getState().isInViewMode) {
				return;
			}
			setState({ selectedToEditCardId: cardId });
		},
	setSelectedToRemoveCardId:
		(cardId: string | null) =>
		({ getState, setState }: StoreActionApi<CustomSitesExtensionsState>) => {
			if (!fg('company_hub_manual_cards_refinement')) {
				return;
			}

			if (getState().isInViewMode) {
				return;
			}
			setState({ selectedToRemoveCardId: cardId });
		},
};

const CustomSitesExtensionsStore = createStore({
	initialState,
	actions,
	name: 'CustomSitesExtensionsStore',
});

export const useCustomSitesExtensions = createHook(CustomSitesExtensionsStore);
