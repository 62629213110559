import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import {
	CustomBlock,
	MetadataBlock,
	PreviewBlock,
	SnippetBlock,
	TitleBlock,
	ElementName,
	SmartLinkSize,
	SmartLinkTheme,
	SmartLinkPosition,
	FooterBlock,
	ActionName,
	type MediaPlacement,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';
import { WidthObserver } from '@atlaskit/width-detector';

import { EmojiRenderer } from '../EmojiRenderer';

import {
	getTitleBlockStyles,
	CommonCarouselWrapperStyles,
	getCustomImageUploadStyles,
	HeroCardWrapper,
	floatingEmojiStyles,
} from './cardComponentsStyles';
import { CustomImageUpload } from './CustomImageUpload';
import { getImageTypeToShow } from './getImageTypeToShow';
import type { HeroCardProps } from './HeroCard';

type HeroCardLinkComponentProps = {
	emojiId: string | null;
	isDisabledTextColor?: boolean;
	previewImagePlacement?: MediaPlacement;
	handleViewAction: () => void;
	throttledSetWidth: any;
	isVerticalLayout: boolean;
	onError: any;
	onResolve: () => void;
	showDescriptionPlaceholderText: boolean;
	isUnauthorized: boolean;
} & Omit<
	HeroCardProps,
	| 'isInViewMode'
	| 'onCardSucceeded'
	| 'onCardFailed'
	| 'createAnalyticsEvent'
	| 'analyticsSource'
	| 'extensionType'
	| 'imagePosition'
	| 'imageAltText'
>;

const descriptionStylesOverride = (showDescriptionPlaceholderText) => ({
	'> span': {
		font: token('font.body', fontFallback.body.medium),
		textAlign: 'left',
		color: showDescriptionPlaceholderText ? token('color.text.subtlest', N200) : 'unset',
	},
});

const emojiStylesOverride = {
	'> span': {
		margin: `${token('space.025', '2px')} 0`,
	},
};

const i18n = defineMessages({
	viewButtonText: {
		id: 'custom-sites-extensions.hero-card.view',
		defaultMessage: 'View',
		description:
			'Text shown on the button on the largest card of the carousel component to view the content',
	},
});

const footerBlockStylesOverride = {
	flexGrow: 1,
	' > div': {
		alignSelf: 'flex-end',
		maxHeight: 'fit-content',
	},
	' > span': {
		alignSelf: 'flex-end',
	},
};

export const HeroCardLinkComponent = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	isAvatarShown,
	isPublishDateShown,
	description,
	isVerticalLayout,
	previewImagePlacement,
	isEmptyCard = false,
	handleViewAction,
	throttledSetWidth,
	onError,
	onResolve,
	showDescriptionPlaceholderText,
	isDisabledTextColor = false,
	emojiId,
	ariaLabel,
	isUnauthorized,
}: HeroCardLinkComponentProps) => {
	const { showUnsplashImage, showCustomUploadedImage } = getImageTypeToShow({
		imageSrc,
	});

	return (
		<CommonCarouselWrapperStyles isHeroCard>
			<HeroCardWrapper
				cardHeight={cardHeight}
				isPublishDateShown={isPublishDateShown}
				isVerticalLayout={isVerticalLayout}
				aria-label={ariaLabel}
				tabIndex={0}
			>
				<SmartCardSSR
					id={cardId}
					appearance="block"
					frameStyle="hide"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: SmartLinkSize.XLarge,
						hideElevation: true,
					}}
					url={link}
					onResolve={onResolve}
					onError={onError}
				>
					{showUnsplashImage && (
						<PreviewBlock
							ignoreContainerPadding
							placement={previewImagePlacement}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock
							overrideCss={getCustomImageUploadStyles(previewImagePlacement, true) as any}
						>
							<CustomImageUpload
								imageContentId={contentId}
								imageSrc={imageSrc}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock
						overrideCss={
							isVerticalLayout ? (floatingEmojiStyles as any) : (emojiStylesOverride as any)
						}
						testId="smart-block-link-card-emoji-container"
					>
						<EmojiRenderer emojiId={emojiId} height={24} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.AuthorGroup }] : []}
						position={SmartLinkPosition.Top}
						text={title}
						size={SmartLinkSize.XLarge}
						maxLines={2}
						anchorTarget="_blank"
						overrideCss={
							getTitleBlockStyles({
								isHeroCard: true,
								isDisabledTextColor,
								disablePointerEvents: true,
								isUnauthorized,
							}) as any
						}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{!isUnauthorized && (
						<SnippetBlock
							text={description}
							overrideCss={descriptionStylesOverride(showDescriptionPlaceholderText) as any}
							maxLines={isVerticalLayout ? 3 : 6}
						/>
					)}
					{!isEmptyCard && (
						<FooterBlock
							overrideCss={footerBlockStylesOverride as any}
							alwaysShow
							hideProvider
							actions={[
								{
									name: ActionName.CustomAction,
									onClick: handleViewAction,
									hideIcon: true,
									content: <FormattedMessage {...i18n.viewButtonText} />,
									href: link,
									ariaLabel: title,
								},
							]}
							size={SmartLinkSize.XLarge}
						/>
					)}
				</SmartCardSSR>
				<WidthObserver setWidth={throttledSetWidth} offscreen />
			</HeroCardWrapper>
		</CommonCarouselWrapperStyles>
	);
};
